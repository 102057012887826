import { CSS } from '@stitches/react';
import { ReactNode } from 'react';
import { styled } from 'stitches.config';

type PropType = {
  layout?: string;
  children: ReactNode;
  css?: CSS;
};

function GridItem({ layout = '1/1', children, css }: PropType) {
  return (
    <GridItemContainer data-gridsize={layout} css={css}>
      {children}
    </GridItemContainer>
  );
}

const GridItemContainer = styled('div', {
  w: '100%',
  h: '100%',
  mx: 'auto',
  gridColumnStart: 'auto',
  gridColumnEnd: 'span 12',
  '@mediaMinMedium': {
    '&:nth-child(n)[data-gridsize="1/2"], &:nth-child(n)[data-gridsize="1/3"], &:nth-child(n)[data-gridsize="1/4"]':
      {
        gridColumnEnd: 'span 6',
      },
  },
  '@mediaMinLarge': {
    '&:nth-child(n)[data-gridsize="1/3"]': {
      gridColumnEnd: 'span 4',
    },
    '&:nth-child(n)[data-gridsize="1/4"]': {
      gridColumnEnd: 'span 3',
    },
    mx: 0,
  },
  '&:nth-child(n)[data-gridsize="FullWidth"]': {
    ml: -4,
    w: '100vw',
    '@mediaMinLarge': {
      px: 0,
      left: '50%',
      right: '50%',
      maxWidth: '100vw',
      position: 'relative',
      marginLeft: `-50vw`,
      marginRight: '-50vw',
    },
  },
});

export default GridItem;
