import React from 'react';
import { styled } from 'stitches.config';

type SmallLabelProps = {
  children: React.ReactNode;
  marginBottom?: number;
};

const SmallLabel = ({ children, marginBottom = 2 }: SmallLabelProps) => (
  <SmallLabelWrapper css={{ mb: marginBottom }}>{children}</SmallLabelWrapper>
);

const SmallLabelWrapper = styled('span', {
  color: '$secondary2',
  textTransform: 'uppercase',
  fs: 6,
  lineHeight: 'lh133',
  ls: '$ls1',
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  display: 'block',
});

export default SmallLabel;
