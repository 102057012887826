import CoWorkersBlockModel from 'Models/Blocks/CoWorkerBlock/CoWorkersBlockModel.interface';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import placeholder from './placeholder.svg';
import GridItem from 'Atoms/Grids/GridItem';
import { styled } from 'stitches.config';
import SmallLabel from 'Atoms/Typography/SmallLabel/SmallLabel';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { isEmpty } from 'Shared/Common/Helpers';
import KexLink from 'Kex/KexLink';
import { ArrowIcon } from 'Atoms/Icons';
import Image from 'Atoms/Image/Image';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';

type PropType = {
  content: CoWorkersBlockModel;
};

function CoworkersBlock({
  content: { targetText, targetLink, coWorkers, headerText },
}: PropType) {
  return (
    <GridItem layout="FullWidth">
      <CoworkerContainer>
        <ContentContainer>
          <H2>{headerText}</H2>
          <CoworkerList>
            {coWorkers.map((cw, index) => (
              <CoworkerItem key={index}>
                <CoworkerImageWrapper>
                  <Image
                    alt=""
                    src={
                      !isEmpty(cw.image)
                        ? `${cw.image.src}?preset=coworker`
                        : placeholder
                    }
                  />
                </CoworkerImageWrapper>
                <SmallLabel>{cw.role}</SmallLabel>
                <CoworkerName>{cw.name}</CoworkerName>
              </CoworkerItem>
            ))}
            <CoworkerLink href={targetLink.href} fontFamily="primary400">
              <LinkWrapper>
                <LinkText>{targetText}</LinkText>
                <ArrowIcon size={'m'} color={'primary'} rotateLeft />
              </LinkWrapper>
            </CoworkerLink>
          </CoworkerList>
        </ContentContainer>
      </CoworkerContainer>
    </GridItem>
  );
}

const MOBILE_GUTTER = 4;
const DESKTOP_GUTTER = 8;

const CoworkerContainer = styled('div', {
  backgroundColor: '$primary5',
  py: 16,
});

const CoworkerList = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  ml: -MOBILE_GUTTER,
  '@mediaMinMedium': {
    ml: -DESKTOP_GUTTER,
  },
});

const CoworkerItem = styled('div', {
  flex: '1 0 50%',
  maxWidth: '50%',
  pl: MOBILE_GUTTER,
  pb: MOBILE_GUTTER,
  '@mediaMinMedium': {
    pl: 8,
    pb: 8,
    flex: '1 0 33.33%',
    maxWidth: '33.33%',
  },
  '@mediaMinLarge': {
    flex: '1 0 16.66%',
    maxWidth: '16.66%',
  },
});

const CoworkerImageWrapper = styled('div', {
  backgroundColor: '$primary6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mb: 4,
  '& img': {
    objectFit: 'cover',
    aspectRatio: '1/1',
    width: '100%',
  },
});

const CoworkerName = styled('span', {
  fs: 10,
  lineHeight: '$lh12',
  fontFamily: '$fontSecondary600',
});

const CoworkerLink = styled(KexLink, {
  backgroundColor: '$primary3',
  fontFamily: '$fontPrimary400',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  minHeight: 120,
  p: 6,
  flexGrow: 1,
  flexBasis: '100%',
  ml: MOBILE_GUTTER,
  mb: MOBILE_GUTTER,
  '@mediaMinMedium': {
    ml: DESKTOP_GUTTER,
    mb: DESKTOP_GUTTER,
    flexBasis: 'auto',
    p: 8,
  },
  '&:hover span': {
    ...animateUnderlineActive,
  },
});

const LinkWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const LinkText = styled('span', {
  fs: 10,
  mb: 0,
  mr: 2,
  ...animateUnderlineNotActive,
  '@mediaMinLarge': {
    fs: 12,
  },
});

export default CoworkersBlock;
